
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'WordOrderExport'
})
export default class WordOrderExport extends Vue {
  private loading = false
  private tableData: Array<any>= []
  page = 1
  size = 10
  total = 0

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.newOrder.selectFlowOrderFileByPage, {
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onDelete (file: { id: string; fileName: string }) {
    this.$confirm(`是否删除文件【${file.fileName}】?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.newOrder.deleteFlowOrderFile,
        {
          id: file.id
        }).then(() => {
        this.$message.success('删除成功')
        this.getData()
      })
    })
  }

  // 下载
  onDownload (file: { fileUrl: string; fileName: string }) {
    window.location.href = `${file.fileUrl}?attname=${file.fileName}`
  }
}
